<template>
    <div>
        <page-header v-if="single" :payload="{ heading: single.title.rendered }"></page-header>

        <section v-if="single" class="content">
            <b-container>
                <b-row>
                    <b-col v-html="single.content.rendered" cols="12" lg="10" offset-lg="1"></b-col>
                </b-row>
            </b-container>
        </section>

        <b-container>
            <b-row>
                <b-col>
                    <hr/>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import PageHeader from "../components/blocks/Header";

    export default {
        name: "Single",
        components: { PageHeader },
        computed: {
            single() {
                return this.$store.getters.post(this.$route.params.slug)
            },

            type() {
                return this.$route.meta.type
            },
        },
        mounted() {
          this.$store.dispatch('getPost', this.$route.params.slug)
          this.$store.dispatch('getAllPosts')
        }
    }
</script>

<style scoped lang="scss">
    header {
        background: $primary;
        color: $white;
        padding-top: 5rem;
        padding-bottom: 24px;

        h2{ margin-bottom: 16px; }

        .separator {
            margin: 0 0.75rem;
        }

        .tags{
            display: inline-flex;
            flex-direction: row;
            list-style-type: none;
            flex-wrap: wrap;
            margin: 16px 0 0;
            padding: 0;

            li{
                position: relative;
                padding: 0 5px;
                margin: 0 8px 8px 0;
                cursor: pointer;
                color: $primary;
                background: $blue-100;
                border-radius: 10px;
                transition: 0.15s ease;

                a{
                    display: inline-flex;
                    align-items: center;
                }

                svg{
                    width: 12px;
                    height: 12px;
                    fill: $primary;
                    transition: 0.15s ease;
                    margin-left: 5px;
                }

                &:hover{
                    background: $primary;
                    color: $white;

                    svg{ fill: $white; }
                }
            }

            @include media-breakpoint-up(lg){
                margin: 0;
            }
        }

        hr {
            border-color: #8E9EB6;
            margin-bottom: 16px;
            margin-top: 80px;
        }

        a {
            color: $white;
        }

        .bc {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.3px;

            .separator {
                margin: 0 0.75rem;
            }
        }
    }

    section.content {
        padding-top: 104px;
        padding-bottom: 104px;
    }

    section.related {
        padding-top: 104px;
        padding-bottom: 104px;

        .link{
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            color: $gray-900;
            @extend %navigation-menu;

            svg{
                width: 16px;
                height: 16px;
                fill: $gray-900;
            }
        }
    }

    .st0{fill-rule:evenodd;clip-rule:evenodd;}
</style>

<style lang="scss">
    section.content {
        img{
            max-width: 100%;
            height: auto;
        }
    }
</style>